import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  init() {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}google.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'gamerCoin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}gc.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'info_out',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}info_out.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'val_positiva',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}val_positiva.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'val_negativa',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}val_negativa.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'logo_activo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}logo_activo.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'logo_inactivo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}logo_inactivo.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}facebook.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}instagram.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'x',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}twitter_x.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}youtube.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}linkedin.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'tiktok',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}tiktok.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'twitch',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}twitch.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'info_out_warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.svgIcons}info_out_warning.svg`,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'check_circle_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.svgIcons}check_circle_outline.svg`,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'timer',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}timer_icon.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'info_out_success',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}check_ok.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}error.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'cuenta_add',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}cuenta_add.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'cuenta_fact',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}cuenta_fact.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'comment',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}comment.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'list_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}list.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'grid_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}grid.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'info_primary',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}info_primary.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'trash_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}trash_icon.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_minimal',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}arrow-minimal.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'pin_plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}pin_plus.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'edit_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}edit_outline.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'info_yellow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.svgIcons}info_outline_24px.svg`,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'amd',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}AMD.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'intel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}Intel.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'coolerfan',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}cooler.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'hdd',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}hdd.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'ssd',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}ssd.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'cancel_full',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}cancel_full.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'reloj',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}reloj.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'thumb_down_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${environment.svgIcons}thumb_down_white.svg`,
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'thumb_down_red',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}thumb_down_red.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'thumb_up_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}thumb_up_white.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'thumb_up_green',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}thumb_up_green.svg`),
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.svgIcons}send.svg`),
    );
  }
}
